export class Device {
    constructor(deviceType = 'gamepad', host = false) {
        /* The device state */
        this.state = {};
        /* The custom device state */
        this.customState = {};
        /* The local device state */
        this.localState = {};
        /**
         * The player's informations
         */
        this.player = {
            uid: '',
            username: '',
            avatar: 'https://avatars.dicebear.com/api/adventurer/.svg',
            color: '',
        };
        this.state = { deviceType, host };
        this.customState = {};
        this.localState = {};
    }
    /* MANAGE PLAYER'S INFOS */
    getPlayer() {
        return this.player;
    }
    setPlayer(player) {
        this.player = player;
    }
    /* MANAGE DEVICE STATES */
    getDeviceState() {
        return this.state;
    }
    setDeviceState(deviceState) {
        this.state = deviceState;
    }
    getDeviceStateProperty(name) {
        return this.state[name];
    }
    setDeviceStateProperty(name, value) {
        this.state[name] = value;
    }
    /* MANAGE CUSTOM DEVICE STATES */
    getCustomDeviceState() {
        return this.customState;
    }
    setCustomDeviceState(customDeviceState) {
        this.customState = customDeviceState;
    }
    getCustomDeviceStateProperty(name) {
        return this.customState[name];
    }
    setCustomDeviceStateProperty(name, value) {
        this.customState[name] = value;
    }
    clearCustomDeviceState() {
        this.customState = {};
    }
    clearCustomDeviceStateProperty(name) {
        delete this.customState[name];
    }
    /* MANAGE CUSTOM DEVICE STATES */
    getLocalDeviceStateProperty(name) {
        return this.localState[name];
    }
    setLocalDeviceStateProperty(name, value) {
        this.localState[name] = value;
    }
    clearLocalDeviceState() {
        this.localState = {};
    }
    clearLocalDeviceStateProperty(name) {
        delete this.localState[name];
    }
    isHost() {
        return this.getDeviceStateProperty('host');
    }
    isScreen() {
        return (this.getDeviceStateProperty('deviceType') === 'screen');
    }
    isGamepad() {
        return (this.getDeviceStateProperty('deviceType') === 'gamepad');
    }
    getConnectionPosition() {
        return this.getDeviceStateProperty('connectionPosition');
    }
}
