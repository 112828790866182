/**
 * The enumeration of the different types of communication.
 */
export var CommunicationType;
(function (CommunicationType) {
    CommunicationType["SocketIO"] = "SocketIO";
    CommunicationType["WebView"] = "WebView";
})(CommunicationType || (CommunicationType = {}));
/**
 * Namespace grouping functions to easily access
 * standard configuration.
 */
export var CommunicationConfig;
(function (CommunicationConfig) {
    function getDefault() {
        return CommunicationConfig.getWebView();
    }
    CommunicationConfig.getDefault = getDefault;
    function getWebView() {
        return {
            type: CommunicationType.WebView,
            opts: {}
        };
    }
    CommunicationConfig.getWebView = getWebView;
    function getSocketIO(deviceType, server, session, player) {
        if (session.id) {
            return {
                type: CommunicationType.SocketIO,
                opts: {
                    server: server,
                    createSession: false,
                    sessionID: session.id,
                    sessionPassword: session.password,
                    player: player,
                    deviceType: deviceType,
                }
            };
        }
        else if (session.url) {
            return {
                type: CommunicationType.SocketIO,
                opts: {
                    server: server,
                    createSession: true,
                    sessionURL: session.url,
                    sessionPassword: session.password,
                    player: player,
                    deviceType: deviceType,
                }
            };
        }
        else {
            throw Error('[Communication.getSocketIO] requires session.id or session.url to be set');
        }
    }
    CommunicationConfig.getSocketIO = getSocketIO;
})(CommunicationConfig || (CommunicationConfig = {}));
