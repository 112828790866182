var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class EventManager {
    constructor(eventNames) {
        /* Events */
        this.events = new Map();
        this.events = new Map(eventNames === null || eventNames === void 0 ? void 0 : eventNames.map((evName) => [evName, new Map()]));
    }
    /**
     * Turn on an event listener.
     * @param message the event name
     * @param callback a callback function
     * @returns the event identifier
     */
    on(message, callback) {
        const eventID = EventManager.eventCounter++;
        const eventMap = this.events.get(message);
        if (eventMap === undefined) {
            this.events.set(message, new Map([[eventID, callback]]));
        }
        else {
            eventMap.set(eventID, callback);
        }
        return eventID;
    }
    /**
     * Turn off a message event listener.
     * @param message the message
     * @param eventID the event id
     */
    off(message, eventID) {
        const eventMap = this.events.get(message);
        if (eventMap === undefined) {
            throw Error(`No event <${message}> found in this scope.`);
        }
        else {
            const isDeleted = eventMap.delete(eventID);
            if (!isDeleted) {
                throw Error(`No event ${eventID} found for event <${message}>.`);
            }
            if (eventMap.size === 0) {
                this.events.delete(message);
            }
        }
    }
    /**
     * Handle an event
     * @param eventName the event name
     * @param data the data
     */
    dispatch(eventName, data) {
        let callAsync = (fct) => __awaiter(this, void 0, void 0, function* () { return fct(data); });
        const eventsMap = this.events.get(eventName);
        if (eventsMap !== undefined) {
            let callbackIterator = eventsMap.values();
            let element = callbackIterator.next();
            while (!element.done) {
                callAsync(element.value);
                element = callbackIterator.next();
            }
        }
    }
    has(key) {
        return this.events.has(key);
    }
}
/* Static properties shared by all instances */
EventManager.eventCounter = 0;
