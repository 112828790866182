import React, { useEffect, useState } from 'react';
import { useApi, useDeviceStateManager, useSceneManager, usePlayersManager, useDeviceStatePropertyManager, useCustomDeviceStatePropertyManager, } from '../../';
import { SocketIoApi, WebViewApi } from '../../../vanilla';
import { CommunicationConfig } from './communication_config';
/**
 * This component instanciates an Unboared game.
 *
 * @version 0.2.0
 * @author [David Albert](https://david-albert.com)
 *
 */
export const UnboaredGame = ({ name, initialScene, children, api, loader = null, config = CommunicationConfig.getDefault(), }) => {
    const { api: localApi, setApi } = useApi();
    const [isReady, setReady] = useState(false);
    useEffect(() => {
        let newApi;
        if (api) {
            newApi = api;
        }
        else {
            if (config.type === 'WebView' || config.type === 'Iframe') {
                newApi = new WebViewApi(initialScene).init();
            }
            else if (config.type === 'SocketIO') {
                newApi = new SocketIoApi(initialScene, config.opts.deviceType, config.opts.server).init();
            }
            else {
                throw Error(`${config.type} type not known`);
            }
        }
        newApi.onReady(() => {
            setReady(true);
        });
        if (newApi.isTopLevelApp()) {
            if (config.opts.createSession) {
                newApi.createSession(config.opts.sessionPassword, config.opts.sessionURL, config.opts.player);
            }
            else {
                newApi.joinSession(config.opts.sessionID, config.opts.sessionPassword, config.opts.player);
            }
        }
        else {
            newApi.start();
        }
        setApi(newApi);
    }, []);
    if (!localApi || !isReady) {
        return loader;
    }
    return (React.createElement(UnboaredGameInit, { loader: loader, children: children }));
};
const UnboaredGameInit = ({ children, loader }) => {
    const [sceneReady, setSceneReady] = useState(false);
    const [playersReady, setPlayersReady] = useState(false);
    const [activePlayersReady, setActivePlayersReady] = useState(false);
    const [deviceStateReady, setDeviceStateReady] = useState(false);
    const [devStatePropertyReady, setDevStatePropertyReady] = useState(false);
    const [customDevStatePropertyReady, setCustomDevStatePropertyReady,] = useState(false);
    useSceneManager(() => setSceneReady(true));
    usePlayersManager(() => setPlayersReady(true));
    usePlayersManager(() => setActivePlayersReady(true));
    useDeviceStateManager(() => setDeviceStateReady(true));
    useDeviceStatePropertyManager(() => setDevStatePropertyReady(true));
    useCustomDeviceStatePropertyManager(() => setCustomDevStatePropertyReady(true));
    if (!sceneReady ||
        !playersReady ||
        !deviceStateReady ||
        !devStatePropertyReady ||
        !customDevStatePropertyReady ||
        !activePlayersReady) {
        return loader;
    }
    return React.createElement(React.Fragment, null, children);
};
