"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    Object.defineProperty(o, k2, { enumerable: true, get: function() { return m[k]; } });
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __exportStar = (this && this.__exportStar) || function(m, exports) {
    for (var p in m) if (p !== "default" && !Object.prototype.hasOwnProperty.call(exports, p)) __createBinding(exports, m, p);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RemContext = exports.FontSizeContext = exports.SharedValue = exports.cssToRNStyle = void 0;
const RN = __importStar(require("react-native"));
require("./polyfill");
const styleComponent_1 = __importStar(require("./styleComponent"));
var cssToRN_1 = require("./cssToRN");
Object.defineProperty(exports, "cssToRNStyle", { enumerable: true, get: function () { return cssToRN_1.cssToRNStyle; } });
var styleComponent_2 = require("./styleComponent");
Object.defineProperty(exports, "SharedValue", { enumerable: true, get: function () { return styleComponent_2.SharedValue; } });
Object.defineProperty(exports, "FontSizeContext", { enumerable: true, get: function () { return styleComponent_2.FontSizeContext; } });
Object.defineProperty(exports, "RemContext", { enumerable: true, get: function () { return styleComponent_2.RemContext; } });
__exportStar(require("./useTheme"), exports);
const styled = (Component) => (0, styleComponent_1.default)(Component);
styled.ActivityIndicator = styled(RN.ActivityIndicator);
styled.Button = styled(RN.Button);
styled.DrawerLayoutAndroid = styled(RN.DrawerLayoutAndroid);
styled.Image = styled(RN.Image);
styled.ImageBackground = styled(RN.ImageBackground);
styled.KeyboardAvoidingView = styled(RN.KeyboardAvoidingView);
styled.Modal = styled(RN.Modal);
styled.NavigatorIOS = styled(RN.NavigatorIOS);
styled.ScrollView = styled(RN.ScrollView);
styled.SnapshotViewIOS = styled(RN.SnapshotViewIOS);
styled.Switch = styled(RN.Switch);
styled.RecyclerViewBackedScrollView = styled(RN.RecyclerViewBackedScrollView);
styled.RefreshControl = styled(RN.RefreshControl);
styled.SafeAreaView = styled(RN.SafeAreaView);
styled.StatusBar = styled(RN.StatusBar);
styled.Text = styled(RN.Text);
styled.TextInput = styled(RN.TextInput);
styled.TouchableHighlight = styled(RN.TouchableHighlight);
styled.TouchableNativeFeedback = styled(RN.TouchableNativeFeedback);
styled.TouchableOpacity = styled(RN.TouchableOpacity);
styled.TouchableWithoutFeedback = styled(RN.TouchableWithoutFeedback);
styled.View = styled(RN.View);
styled.FlatList = styleComponent_1.styledFlatList;
styled.SectionList = styleComponent_1.styledSectionList;
styled.VirtualizedList = styleComponent_1.styledVirtualizedList;
exports.default = styled;
