import { useEffect } from "react";
import { useApi } from "../stores/useApi";
export const useListener = (name, callback, listDeps = []) => {
    const { api } = useApi();
    const fun_log = (from, data) => {
        // console.log(`[useListener(${name})] Receive data ${JSON.stringify(data)}`)
        callback(data);
    };
    useEffect(() => {
        const unsubscribe = api.onMessage(name, fun_log);
        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, listDeps);
};
export const useListenerFrom = (name, callback, listDeps = []) => {
    const { api } = useApi();
    useEffect(() => {
        const unsubscribe = api.onMessage(name, callback);
        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, listDeps);
};
