import { useEffect } from 'react';
import { api } from '../useApi';
import { createChangeListener } from '../createChangeListener';
/**
 * Hooks to get the reactive device state properties changes.
 */
const useCustomDeviceStatePropertyChange = createChangeListener();
export const useCustomDeviceStateProperty = () => {
    const isChanged = useCustomDeviceStatePropertyChange(state => state.isChanged);
    return {
        getCustomDeviceStateProperty: (deviceID, key) => api().getCustomDeviceStateProperty(deviceID, key),
        loadCustomDeviceStateProperty: (deviceID, key, value) => api().loadCustomDeviceStateProperty(deviceID, key, value),
    };
};
export const useCustomDeviceStatePropertyManager = (callbackWhenReady) => {
    const change = useCustomDeviceStatePropertyChange(state => state.change);
    useEffect(() => {
        const unsubscribe = api().onCustomDeviceStatePropertyChange((deviceID, key) => {
            change();
        });
        callbackWhenReady();
        return unsubscribe;
    }, []);
};
