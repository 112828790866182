export class Session {
    constructor(id, uid = '', password = '', sessionURL = '') {
        /* The session unique identifier */
        this.sessionUID = '';
        /* The session identifier (readable by human) */
        this.sessionID = '';
        /* The session identifier (readable by human) */
        this.sessionPassword = '';
        /* The url of the session (used to generate QR code) */
        this.sessionURL = '';
        this.sessionID = id;
        this.sessionUID = uid;
        this.sessionPassword = password;
        this.sessionURL = sessionURL;
    }
    isSecured() {
        return !!this.sessionPassword;
    }
    getUID() {
        return this.sessionUID;
    }
    getID() {
        return this.sessionID;
    }
    getURL() {
        return this.sessionURL;
    }
}
