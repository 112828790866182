import { api } from '../useApi';
import { createChangeListener } from '../createChangeListener';
/**
 * Hooks to get the reactive local device state properties changes.
 */
const useLocalDeviceStatePropertyChange = createChangeListener();
export const useLocalDeviceStateProperty = () => {
    const { isChanged, change } = useLocalDeviceStatePropertyChange();
    return {
        getLocalDeviceStateProperty: (deviceID, key) => api().getLocalDeviceStateProperty(deviceID, key),
        setLocalDeviceStateProperty: (deviceID, key, value) => {
            api().setLocalDeviceStateProperty(deviceID, key, value);
            change();
        }
    };
};
