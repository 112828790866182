import { useEffect } from 'react';
import { api } from '../useApi';
import { createChangeListener } from '../createChangeListener';
/**
 * Hooks to get the reactive active players changes.
 */
const useActivePlayersChange = createChangeListener();
export const useActivePlayers = () => {
    const isChanged = useActivePlayersChange(state => state.isChanged);
    return {
        getActivePlayers: () => api().getActivePlayers(),
        loadActivePlayers: (activePlayers) => api().loadActivePlayers(activePlayers),
    };
};
export const useActivePlayersManager = (callbackWhenReady) => {
    const change = useActivePlayersChange(state => state.change);
    useEffect(() => {
        const unsubscribe = api().onActivePlayersChange(() => {
            change();
        });
        callbackWhenReady();
        return unsubscribe;
    }, []);
};
