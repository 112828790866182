import { api } from '../useApi';
export const useSession = () => {
    return {
        /**
         * Gets the connection link of the game controllers.
         * @returns the connexion link
         */
        getSessionLink: () => api().getSessionLink(),
        /**
         * Gets the session id.
         * @returns the session id
         */
        getSessionID: () => api().getSessionID(),
    };
};
