"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const convertUnits_1 = require("./convertUnits");
/** Mix the calculated RN style within the object style */
const convertStyle = (rnStyle, units) => {
    /** This is the result of the convertions from css style into RN style */
    const convertedStyle = {};
    // If width and height are specified, we can use those values for the first render
    ['width', 'height'].forEach(key => {
        if (!units[key] && rnStyle[key]) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const converted = (0, convertUnits_1.convertValue)(key, rnStyle[key], units);
            if (!Number.isNaN(converted))
                units[key] = converted;
        }
    });
    Object.keys(rnStyle).forEach(key => {
        const value = rnStyle[key] || '0';
        // Handle object values
        if (key === 'transform' && rnStyle.transform) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            convertedStyle.transform = rnStyle.transform.map(transformation => {
                const result = {};
                Object.keys(transformation).forEach(k => (result[k] = (0, convertUnits_1.convertValue)(k, transformation[k], units)));
                return result;
            });
        }
        else if (key === 'shadowOffset' && rnStyle.shadowOffset) {
            convertedStyle.shadowOffset = {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                width: (0, convertUnits_1.convertValue)(key, rnStyle.shadowOffset.width || '0', units),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                height: (0, convertUnits_1.convertValue)(key, rnStyle.shadowOffset.height || '0', units)
            };
        }
        else if (key === 'textShadowOffset' && rnStyle.textShadowOffset) {
            convertedStyle.textShadowOffset = {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                width: (0, convertUnits_1.convertValue)(key, rnStyle.textShadowOffset.width || '0', units),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                height: (0, convertUnits_1.convertValue)(key, rnStyle.textShadowOffset.height || '0', units)
            };
        }
        // Font family should not be transformed (same as cursor for web in case of base64 value)
        else if (['cursor', 'fontFamily'].includes(key)) {
            convertedStyle[key] = value;
        }
        else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            convertedStyle[key] = (0, convertUnits_1.convertValue)(key, value, units);
        }
    });
    return convertedStyle;
};
exports.default = convertStyle;
