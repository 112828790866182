import { useSession } from '../useSession';
import { useCommunication } from '../useCommunication';
import { usePlayers } from '../usePlayers';
import { useDeviceStateProperty } from '../useDeviceState';
import { useCustomDeviceStateProperty } from '../useCustomDeviceState';
import { useScene } from '../useScene';
import { useActivePlayers } from '../useActivePlayers';
import { useLocalDeviceStateProperty } from '../useLocalDeviceState';
/**
 * This hooks regroup all the functionalities of the API
 * for a reactive usage with React.js / React Native.
 * @returns an object containing the entire Reactive Unboared API
 */
export const useUnboared = () => {
    const sessionAPI = useSession();
    const communicationAPI = useCommunication();
    const playersAPI = usePlayers();
    const activePlayersAPI = useActivePlayers();
    const deviceStateAPI = useDeviceStateProperty();
    const customDeviceStateAPI = useCustomDeviceStateProperty();
    const localDeviceStateAPI = useLocalDeviceStateProperty();
    const sceneAPI = useScene();
    return Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, sessionAPI), communicationAPI), playersAPI), activePlayersAPI), deviceStateAPI), customDeviceStateAPI), localDeviceStateAPI), sceneAPI);
};
