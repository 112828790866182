import { useEffect } from 'react';
import { api } from '../useApi';
import { createChangeListener } from '../createChangeListener';
/**
 * Hooks to get the reactive device state changes.
 */
const useDeviceStateChange = createChangeListener();
export const useDeviceState = () => {
    const isChanged = useDeviceStateChange(state => state.isChanged);
    return {
        getDeviceState: (deviceID) => api().getDeviceState(deviceID),
        // loadDeviceState: (deviceID: string, state: any) => api().loadDeviceState(deviceID, state),
    };
};
export const useDeviceStateManager = (callbackWhenReady) => {
    const change = useDeviceStateChange(state => state.change);
    useEffect(() => {
        const deviceUnsubscribe = api().onDeviceStateChange(() => {
            change();
        });
        const propUnsubscribe = api().onDeviceStatePropertyChange(() => {
            change();
        });
        const connectUnsubscribe = api().onConnect(() => {
            change();
        });
        const disconnectUnsubscribe = api().onDisconnect(() => {
            change();
        });
        const readyUnsubscribe = api().onReady(() => {
            change();
        });
        callbackWhenReady();
        return () => {
            deviceUnsubscribe();
            propUnsubscribe();
            connectUnsubscribe();
            disconnectUnsubscribe();
            readyUnsubscribe();
        };
    }, []);
};
