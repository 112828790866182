import { useEffect } from 'react';
import { api } from '../useApi';
import { createChangeListener } from '../createChangeListener';
/**
 * Hooks to get the reactive device state properties changes.
 */
const useDeviceStatePropertyChange = createChangeListener();
export const useDeviceStateProperty = () => {
    const isChanged = useDeviceStatePropertyChange(state => state.isChanged);
    return {
        getDeviceStateProperty: (deviceID, key) => api().getDeviceStateProperty(deviceID, key),
        loadDeviceStateProperty: (deviceID, key, value) => api().loadDeviceStateProperty(deviceID, key, value),
    };
};
export const useDeviceStatePropertyManager = (callbackWhenReady) => {
    const change = useDeviceStatePropertyChange(state => state.change);
    useEffect(() => {
        const unsubscribe = api().onDeviceStatePropertyChange((deviceID, key) => {
            change();
        });
        callbackWhenReady();
        return unsubscribe;
    }, []);
};
