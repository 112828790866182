"use strict";
/** polyfill for Node < 12 */
// eslint-disable-next-line no-extend-native
if (!Array.prototype.flat)
    Array.prototype.flat = function (depth) { return flat(this, depth); };
// eslint-disable-next-line no-extend-native
if (!String.prototype.matchAll)
    String.prototype.matchAll = function (regex) { return matchAll(this, regex); };
/** polyfill for Node < 12 */
function flat(array, depth = 1) {
    if (!depth || depth < 1 || !Array.isArray(array))
        return array;
    return array.reduce((result, current) => result.concat(flat(current, depth - 1)), []);
}
function matchAll(str, regex) {
    const matches = [];
    let groups;
    // eslint-disable-next-line no-cond-assign
    while (groups = regex.exec(str)) {
        matches.push(groups);
    }
    return matches[Symbol.iterator]();
}
