import { createGlobalStorage } from "../../zustand/global";
export const useApi = createGlobalStorage((set) => ({
    api: undefined,
    setApi: (api) => {
        set({ api: api });
    },
}));
export const initializeAPI = (apiOrConfig) => {
    useApi.setState({ api: apiOrConfig });
    return api;
};
export const api = () => {
    return useApi.getState().api;
};
export default createGlobalStorage;
