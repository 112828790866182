/**
 * The recommended way to add a behavior when a new player is connected
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device connection
 */
export function onConnect(api, callback) {
    return (typeof api === 'function') ? api().onConnect(callback) : api.onConnect(callback);
}
/**
 * The recommended way to add a behavior when a new player is disconnected
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device disconnection
 */
export function onDisconnect(api, callback) {
    return (typeof api === 'function') ? api().onDisconnect(callback) : api.onDisconnect(callback);
}
/**
 * The recommended way to add a behavior when the scene changed
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device disconnection
 */
export function onSceneChange(api, callback) {
    return (typeof api === 'function') ? api().onSceneChange(callback) : api.onSceneChange(callback);
}
/**
 * The recommended way to add a behavior when a device state property has changed
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device disconnection
 */
export function onDeviceStatePropertyChange(api, callback) {
    return (typeof api === 'function') ? api().onDeviceStatePropertyChange(callback) : api.onDeviceStatePropertyChange(callback);
}
/**
 * The recommended way to add a behavior when a custom device state property has changed
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device disconnection
 */
export function onCustomDeviceStatePropertyChange(api, callback) {
    return (typeof api === 'function') ? api().onCustomDeviceStatePropertyChange(callback) : api.onCustomDeviceStatePropertyChange(callback);
}
/**
 * The recommended way to add a behavior when player changed its properties
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device disconnection
 */
export function onPlayerChange(api, callback) {
    return (typeof api === 'function') ? api().onPlayerChange(callback) : api.onPlayerChange(callback);
}
/**
 * The recommended way to add a behavior when player changed its properties
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device disconnection
 */
export function onActivePlayersChange(api, callback) {
    return (typeof api === 'function') ? api().onActivePlayersChange(callback) : api.onActivePlayersChange(callback);
}
/**
 * The recommended way to add a behavior when a message is received
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device connection
 */
export function onMessage(api, message, callback) {
    return (typeof api === 'function') ? api().onMessage(message, callback) : api.onMessage(message, callback);
}
/**
 * The recommended way to add a behavior when a message is received
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device connection
 */
export function onMessageReceived(api, callback) {
    return (typeof api === 'function') ? api().onMessageReceived(callback) : api.onMessageReceived(callback);
}
/**
 * The recommended way to add a behavior when the game is muted
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device disconnection
 */
export function onMute(api, callback) {
    return (typeof api === 'function') ? api().onMute(callback) : api.onMute(callback);
}
/**
 * The recommended way to add a behavior when the game is paused
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device disconnection
 */
export function onPause(api, callback) {
    return (typeof api === 'function') ? api().onPause(callback) : api.onPause(callback);
}
/**
 * The recommended way to add a behavior when the game is resumed
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device disconnection
 */
export function onResume(api, callback) {
    return (typeof api === 'function') ? api().onResume(callback) : api.onResume(callback);
}
/**
 * The recommended way to add a behavior when the game is resumed
 * is by setting an observer on the UnboaredAPI object:
 * @param unboaredAPI the unboared api instance
 * @param callback the function to call when at a device disconnection
 */
export function onReady(api, callback) {
    return (typeof api === 'function') ? api().onReady(callback) : api.onReady(callback);
}
