const eventPairs = [
    ['ready', 'onReady'],
    ['connect', 'onConnect'],
    ['disconnect', 'onDisconnect'],
    ['message', 'onMessage'],
    ['setScene', 'onSceneChange'],
    ['setDeviceState', 'onDeviceStateChange'],
    ['setDeviceStateProperty', 'onDeviceStatePropertyChange'],
    ['mute', 'onMute'],
    ['pause', 'onPause'],
    ['resume', 'onResume'],
];
const eventMap = new Map(eventPairs.map(eventPair => [eventPair[0], eventPair[1]]));
const eventMapRev = new Map(eventPairs.map(eventPair => [eventPair[1], eventPair[0]]));
export const EventDispatcherList = eventPairs.map(eventPair => eventPair[0]);
export const EventListenerList = eventPairs.map(eventPair => eventPair[1]);
export const eventConverter = {
    toEventListener: (eventDispatcherName) => {
        if (EventDispatcherList.includes(eventDispatcherName)) {
            return eventMap.get(eventDispatcherName);
        }
    },
    toEventDispatcher: (eventListenerName) => {
        if (EventListenerList.includes(eventListenerName)) {
            return eventMapRev.get(eventListenerName);
        }
    }
};
