import { useEffect } from 'react';
import { api } from '../useApi';
import { createChangeListener } from '../createChangeListener';
/**
 * Hooks to get the reactive Scene change.
 */
const useSceneChange = createChangeListener();
export const useScene = () => {
    const isChanged = useSceneChange(state => state.isChanged);
    const scene = api().getScene();
    const sceneComponent = undefined;
    return {
        scene,
        sceneComponent,
        /**
         * Access to the current scene
         * @returns the name of the current scene
         */
        getScene: () => api().getScene(),
        /**
        * Load a new scene globally.
        * This function load a new scene on every devices.
        * @param {string} scene the target scene
        */
        loadScene: (scene) => api().loadScene(scene),
    };
};
export const useSceneManager = (callbackWhenReady) => {
    const change = useSceneChange(state => state.change);
    useEffect(() => {
        const unsubscribe = api().onSceneChange((scene) => {
            change();
        });
        callbackWhenReady();
        return unsubscribe;
    }, []);
};
