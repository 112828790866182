import { useEffect } from 'react';
import { api } from '../useApi';
import { createChangeListener } from '../createChangeListener';
/**
 * Hooks to get the reactive players' changes.
 */
const usePlayersChange = createChangeListener();
const getPlayer = (deviceID) => ({
    uid: api().getUID(deviceID),
    username: api().getUsername(deviceID),
    avatar: api().getAvatar(deviceID),
    color: api().getColor(deviceID),
});
/**
 * Hooks to get the reactive devices' informations change.
 */
const getPlayers = () => api().getGamepadIDs().map((devID) => getPlayer(devID));
export const usePlayers = () => {
    const isChanged = usePlayersChange(state => state.isChanged);
    return {
        getPlayer: getPlayer,
        getPlayers: getPlayers,
        getGamepadIDs: () => api().getGamepadIDs(),
        getScreenIDs: () => api().getScreenIDs(),
        getDeviceID: () => api().getDeviceID(),
        getHostID: () => api().getHostID(),
        getMasterID: () => api().getMasterID(),
        isMaster: (deviceID) => api().isMaster(deviceID),
        isHost: (deviceID) => api().isHost(deviceID),
        isScreen: (deviceID) => api().isScreen(deviceID),
        getUID: (deviceID) => api().getUID(deviceID),
        getAvatar: (deviceID) => api().getAvatar(deviceID),
        getUsername: (deviceID) => api().getUsername(deviceID),
        getColor: (deviceID) => api().getColor(deviceID),
        loadPlayer: (player) => api().loadPlayer(player),
    };
};
export const usePlayersManager = (callbackWhenReady) => {
    const change = usePlayersChange(state => state.change);
    useEffect(() => {
        const connectUnsubscribe = api().onConnect(() => {
            change();
        });
        const disconnectUnsubscribe = api().onDisconnect(() => {
            change();
        });
        const playerUnsubscribe = api().onPlayerChange(() => {
            change();
        });
        const readyUnsubscribe = api().onReady(() => {
            change();
        });
        callbackWhenReady();
        return () => {
            playerUnsubscribe();
            connectUnsubscribe();
            disconnectUnsubscribe();
            readyUnsubscribe();
        };
    }, []);
};
