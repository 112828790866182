import { api } from '../useApi';
/**
 * Hooks to access the unboared communication functions.
 * @returns returns a set of communication methods
 */
export const useCommunication = () => {
    return {
        /**
         * Send a message to another device in the session.
         * This can either be a screen or a gamepad.
         * @param {string} deviceID the destination id
         * @param {string} message the message
         * @param {any} data additional data
         */
        send: (deviceID, message, data) => api().send(deviceID, message, data),
        /**
         * Send a message to the screen(s).
         * @param {string} message the message
         * @param {any} data additional data
         * @gamepad
         */
        emitAction: (message, data) => api().emitAction(message, data),
        /**
         * Send a message to all the other devices in
         * the session.
         * @param {string} message the message
         * @param {any} data additional data
         */
        broadcast: (message, data) => api().broadcast(message, data)
    };
};
